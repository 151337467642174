import { configMgr } from '@nab/nab-x-sdk-browser';
import { AppConfigs, launch, registerLocalPages, ShellDataManager } from '@usl/usl-base-web-shell';
import React, { useCallback, useLayoutEffect, useRef } from 'react';

import NabEdgeLogo from './assets/images/edge.svg';
import { generateConfigOverrides, getWLOverridingConfig } from './overrides/OverridingConfig';

export function Shell() {
    const ref = useRef<HTMLDivElement>(null);

    const initRuntime = useCallback(async () => {
        if (ref.current) {
            await configMgr.init();
            AppConfigs.getInstance(configMgr).setConfigOverrides(generateConfigOverrides(configMgr));
            await launch(ref.current, getWLOverridingConfig(AppConfigs.getInstance()), <NabEdgeLogo />);
            await registerLocalPages();
            ShellDataManager.getInstance().syncStorage();
        }
    }, [ref]);

    useLayoutEffect(() => {
        initRuntime();
    }, [initRuntime]);

    return (
        <div>
            <main ref={ref} />
        </div>
    );
}
